<template>
  <div>
    <div class="message">
      <div class="inner">
        <div class="title">Interested in Learning More?</div>
        <div class="tip">Please fill out this form and our team will be in touch.</div>
        <div class="form">
          <el-form :model="messageForm" :rules="messageFormRules" ref="messageFormRef" label-position="top">
            <el-form-item prop="email" label="Email">
              <el-input v-model="messageForm.email"></el-input>
            </el-form-item>
            <el-form-item prop="message" label="Give us details on your interest in moozumi. ">
              <el-input v-model="messageForm.message" type="textarea"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center;margin-bottom:0;">
              <el-button type="primary" @click="submitForm" round>Send Message</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <a ref="sendEmailRef" :href="`mailto:service@moozumi.com?subject=Moozumi&body=Email: ${messageForm.email}%0D%0AMessage: ${messageForm.message}`"></a>
  </div>
</template>
<script>
export default {
  data () {
    return {
      messageForm: {
        email: '',
        message: ''
      },
      messageFormRules: {
        email: [
          { required: true, message: 'Please enter a valid email', trigger: 'blur' },
          { type: 'email', message: 'Please enter a valid email', trigger: 'blur' }
        ],
        message: [
          { required: true, message: 'Please enter a valid message', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm () {
      this.$refs.messageFormRef.validate((valid) => {
        if (valid) {
          this.$refs.sendEmailRef.click()
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
@import '../styles/variables.scss';
.message {
  padding: 100px 0 120px;
  background: url(../assets/images/use_cases_merchant/bg5.png);
  background-size: cover;
  .inner {
    width: 1200px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    .title {
      font-family: HelveticaNeue-Bold;
      font-size: 36px;
      color: $white;
      letter-spacing: 0;
      text-align: center;
      line-height: 52px;
    }
    .tip {
      font-family: HelveticaNeue;
      font-size: 20px;
      color: $white;
      letter-spacing: 0;
      text-align: center;
      line-height: 36px;
      margin-top: 15px;
    }
    .form {
      background: $white;
      box-shadow: 0 0 30px 0 rgba(0,0,0,0.10);
      border-radius: 18px;
      text-align: left;
      padding: 32px;
      margin-top: 32px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .message {
    .inner {
      padding: 0 15px;
    }
  }
}
</style>
